<script setup lang="ts">
import type { ExtendedLocationDetail, LocationCoordinate } from '@/types/location';

const options = {
	fields: ['geometry', 'name', 'formatted_address'],
	strictBounds: false,
	types: ['(cities)'],
};

defineProps({
	disabled: {
		type: Boolean,
		default: false,
	},
	isTransparentBackground: {
		type: Boolean,
		default: false,
	},
});

const searchStore = useSearchStore();
const notificationStore = useNotificationStore();
const router = useRouter();
const { isTablet } = useWindowResize();

const isFocusOnLocationInput = ref(false);
const isFocusOnKeywordInput = ref(false);
const isShowLocationMenu = ref(false);
const isGrantedGeolocationPermission = ref(false);
const isSupportedGeolocation = ref(false);
const hasSelectedLocation = ref(false);
const locationInput = ref('');
const keywordInput = ref('');
const coordinate = ref<LocationCoordinate | null>();
const currentCoordinate = ref<LocationCoordinate | null>();
const currentLocationName = ref('');

function handleSavePayload() {
	// Briefly delay closing the location menu to allow user to select an option
	setTimeout(() => {
		if (!isFocusOnLocationInput.value) {
			isShowLocationMenu.value = false;
		}
	}, 200);

	searchStore.payload.selectedLocation.name = locationInput.value;
	searchStore.payload.selectedLocation.coordinates = coordinate.value || null;
	searchStore.payload.keyword = keywordInput.value;
}

function initializeSearch() {
	searchStore.isFetching = true;

	router.push({
		name: ROUTE.SEARCH.NAME,
	});
}

function handleLocationSelected(location: ExtendedLocationDetail) {

	coordinate.value = location.coordinate;
	locationInput.value = location.address;

	searchStore.resetPayload();
	searchStore.payload.countryCode = location.countryCode || '';
	searchStore.payload.selectedLocation.city = location.name;
	searchStore.payload.selectedLocation.country = location.address.split(', ').splice(1).join(', ');

	hasSelectedLocation.value = true;
	handleSavePayload();
	initializeSearch();
}

function handleBlur() {
	handleSavePayload();
	isFocusOnKeywordInput.value = false;
	isFocusOnLocationInput.value = false;
}

function handlePressEnter() {
	// Prevent search from being triggered if no location has been selected
	// or user type something apart from 'Everywhere' keyword
	if (
		!hasSelectedLocation.value &&
    locationInput.value &&
    locationInput.value !== 'Everywhere'
	) {
		return;
	}

	handleSavePayload();
	initializeSearch();
}

function handleFocusOnLocationInput() {
	isFocusOnLocationInput.value = true;

	if (!locationInput.value) {
		isShowLocationMenu.value = true;
	}
}

function handleFocusOnKeywordInput() {
	isFocusOnKeywordInput.value = true;
}

async function getGeolocation(isSelectCurrentLocation = false) {
	if (!navigator.geolocation) {
		isSupportedGeolocation.value = false;
		notificationStore.showWarningNotification('Geolocation is not supported by this browser.');
		return;
	}

	isSupportedGeolocation.value = true;
	const permission = await navigator.permissions.query({ name: 'geolocation' });
	const geolocationOptions = {
		timeout: 5000, //  If the response takes longer than this time, the browser will throw an error.
		maximumAge: 0, // Don't cache location
	};

	if (permission.state === 'denied' && isSelectCurrentLocation) {
		notificationStore.showWarningNotification('Please allow location permission in your browser settings.');
	} else if (permission.state === 'granted') {
		navigator.geolocation.getCurrentPosition((position) => {
			currentCoordinate.value = {
				lat: position.coords.latitude,
				lng: position.coords.longitude,
			};
			isGrantedGeolocationPermission.value = true;
		}, () => { }, geolocationOptions);
	} else if (permission.state === 'prompt') {
		navigator.geolocation.getCurrentPosition((position) => {
			currentCoordinate.value = {
				lat: position.coords.latitude,
				lng: position.coords.longitude,
			};
			isGrantedGeolocationPermission.value = true;
		}, () => {
			notificationStore.showWarningNotification('Please allow location permission in your browser settings.');
		}, geolocationOptions);
	}
}

async function handleSelectCurrentLocation() {
	// Request user's current location if geolocation permission has not been granted
	if (!isGrantedGeolocationPermission.value) {

		await getGeolocation(true);
	}
	const { cityName, countryName, countryCode } = await useGeocoder({}, currentCoordinate.value);

	searchStore.resetPayload();

	// Output: "Bangkok, Thailand" or "New York, United States"
	currentLocationName.value = cityName ? `${cityName}, ${countryName}` : `${countryName}`;
	searchStore.payload.currentLocation.city = cityName;
	searchStore.payload.currentLocation.country = countryName;
	searchStore.payload.countryCode = countryCode;

	coordinate.value = currentCoordinate.value;
	locationInput.value = currentLocationName.value;

	searchStore.payload.selectedLocation.city = searchStore.payload.currentLocation.city;
	searchStore.payload.selectedLocation.country = searchStore.payload.currentLocation.country;

	hasSelectedLocation.value = true;
	handleSavePayload();
	initializeSearch();
}

function handleSelectEverywhere() {
	locationInput.value = 'Everywhere';
	coordinate.value = null;

	searchStore.resetPayload();
	handleSavePayload();
	initializeSearch();
}

function handleLocationChange(value: string) {
	locationInput.value = value;
}

function handleKeywordChange(value: string) {
	keywordInput.value = value;
}

watch(() => locationInput.value, (newValue) => {
	if (newValue) {
		isShowLocationMenu.value = false;
	} else {
		isShowLocationMenu.value = true;
	}
});

onMounted(async () => {
	await getGeolocation();
});
</script>

<template>
  <div>
    <!-- Search box in desktop screen -->
    <template v-if="!isTablet">
      <div
        :class="[
          'search-box-container',
          {
            '--focus': isFocusOnKeywordInput || isFocusOnLocationInput,
            '--disabled': disabled,
          }
        ]"
      >
        <div class="keyword-search-input">
          <SearchIcon
            class="icon"
            width="20"
            height="20"
            color="#667085"
          />
          <input
            ref="inputElement"
            v-model="keywordInput"
            placeholder="Search events"
            class="text-field"
            :disabled="disabled"
            @focus="handleFocusOnKeywordInput"
            @blur="handleBlur"
            @keydown.enter="handlePressEnter"
          >
        </div>

        <div class="location-search-input">
          <div class="input-wrapper">
            <div class="separator" />
            <BaseGoogleMapsAutoComplete
              id="menu-activator"
              v-model="locationInput"
              :options="options"
              placeholder="Search by city"
              :disabled="disabled"
              is-search-bar
              @on-select-location="handleLocationSelected"
              @focus="handleFocusOnLocationInput"
              @blur="handleBlur"
              @enter="handlePressEnter"
            >
              <template #icon>
                <MarkerPinIcon
                  class="icon"
                  color="#667085"
                />
              </template>
            </BaseGoogleMapsAutoComplete>

            <VList
              v-if="isShowLocationMenu"
              class="location-menu"
            >
              <VListItem
                v-if="isSupportedGeolocation"
                min-height="36"
                class="text-regular"
                :ripple="false"
                @click="handleSelectCurrentLocation"
              >
                <button class="location-menu-item">
                  <MarkerPinAltIcon color="#475467" />
                  <p>Use my current location</p>
                </button>
              </VListItem>
              <VListItem
                min-height="36"
                class="text-regular"
                :ripple="false"
                @click="handleSelectEverywhere"
              >
                <button class="location-menu-item">
                  <GlobePinIcon color="#475467" />
                  <p>Everywhere</p>
                </button>
              </VListItem>
            </VList>
          </div>
        </div>
      </div>
    </template>
    <!-- End Search box in desktop screen -->

    <!-- Search box in tablet screen -->
    <SearchMenu
      v-else
      :location-input="locationInput"
      :keyword-input="keywordInput"
      :is-transparent-background="isTransparentBackground"
      :is-show-location-menu="isShowLocationMenu"
      :options="options"
      @on-location-input-change="handleLocationChange"
      @on-keyword-input-change="handleKeywordChange"
      @on-select-location="handleLocationSelected"
      @on-focus-location="handleFocusOnLocationInput"
      @on-focus-keyword="handleFocusOnKeywordInput"
      @on-select-current-location="handleSelectCurrentLocation"
      @on-select-everywhere="handleSelectEverywhere"
      @blur="handleBlur"
      @enter="handlePressEnter"
    />
    <!-- End Search box in tablet screen -->
  </div>
</template>

<style scoped lang="scss">
.search-box-container {
  display: flex;
  border: 1px solid colors-get(gray, 300);
  @include border-radius-default;
  box-shadow: none;
  background-color: colors-get(base, white);

  &.--focus {
    color: colors-get(gray, 900);
    border: 1px solid colors-get(primary, 300);
    box-shadow:
      0px 0px 0px 4px #f4ebff,
      0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &.--disabled {
    background: colors-get(gray, 50);
  }

  .location-search-input {
    position: relative;

    :deep(.text-field-wrapper) {
      border-radius: rem(8) 0 0 rem(8);
      box-shadow: unset;
      border: 0;

      &.--focus {
        color: inherit !important;
        border: none !important;
        box-shadow: inherit !important;
      }
    }

    :deep(.text-field) {
      width: 100%;
      padding-right: spacings-get(2);
    }

    .icon {
      margin-left: spacings-get(4);
      min-width: spacings-get(5);
    }

    .input-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .separator {
        padding: rem(10) 0;
        border-left: 1px solid colors-get(gray, 300);
      }
    }
  }

  .keyword-search-input {
    display: flex;
    align-items: center;

    .icon {
      margin: 0 spacings-get(2);
      min-width: spacings-get(5);
    }

    .text-field {
      width: 100%;
      padding: rem(10) 0;
      @include border-radius-default;
      @include fonts-get(regular, text-md);
      color: colors-get(gray, 900);
      background: colors-get(base, white);
      outline: none;

      &:disabled {
        background: colors-get(gray, 50);
        color: colors-get(gray, 500);

        &::placeholder {
          color: colors-get(gray, 400);
        }
      }

      &::placeholder {
        color: colors-get(gray, 400);
      }
    }
  }
}
</style>