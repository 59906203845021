<script lang="ts" setup>
defineProps({
	isTransparentBackground: {
		type: Boolean,
		default: false,
		required: true,
	},
});

const { isTablet } = useWindowResize();

const isOpen = ref(false);

function handleClose() {
	isOpen.value = false;
}

const buttonSize = computed(() => {
	return isTablet.value ? BtnSize[0] : BtnSize[2];
});
</script>
<template>
  <div class="guest-nav">
    <template v-if="!isTablet">
      <RouterLink :to="{ name: ROUTE.SIGN_IN.NAME }">
        <BaseButton
          :variant="BtnVariant[2]"
          :color="isTransparentBackground ? BtnColor[3] : BtnColor[1]"
          :size="buttonSize"
        >
          Sign in
        </BaseButton>
      </RouterLink>
      <RouterLink :to="ROUTE.SIGN_UP.PATH">
        <BaseButton
          :variant="BtnVariant[2]"
          :color="isTransparentBackground ? BtnColor[3] : BtnColor[1]"
          :size="buttonSize"
        >
          Register
        </BaseButton>
      </RouterLink>
      <RouterLink :to="{ name: ROUTE.EVENT_CREATE.NAME }">
        <BaseButton
          :variant="isTransparentBackground ? 'outlined' : 'solid'"
          :color="isTransparentBackground ? 'gray' : 'primary'"
          :size="buttonSize"
        >
          Create event
        </BaseButton>
      </RouterLink>
    </template>
    <template v-else>
      <BaseButton
        variant="link"
        @click="isOpen = !isOpen"
      >
        <HamburgerIcon :color="isTransparentBackground ? ICON_COLOR.WHITE : '#667085'" />
      </BaseButton>
      <MenuSideDrawer
        :is-open="isOpen"
        @on-close="handleClose"
      >
        <VList class="menu-list">
          <VListItem
            :ripple="false"
            :to="{
              name: ROUTE.EVENT_CREATE.NAME,
            }"
            @click="handleClose"
          >
            <VListItemAction>Create event</VListItemAction>
          </VListItem>
          <VListItem
            :ripple="false"
            :to="{
              name: ROUTE.SIGN_IN.NAME,
            }"
            @click="handleClose"
          >
            <VListItemAction>Sign in</VListItemAction>
          </VListItem>
          <VListItem
            :ripple="false"
            :to="ROUTE.SIGN_UP.PATH"
            @click="handleClose"
          >
            <VListItemAction>Register</VListItemAction>
          </VListItem>
        </VList>
      </MenuSideDrawer>
    </template>
  </div>
</template>
<style scoped lang="scss">
.guest-nav {
  display: flex;
  align-items: center;
  gap: spacings-get(1);

  button {
    padding: rem(10) rem(18);
    text-wrap: nowrap;

    @include media-query-max(mobile) {
      padding: spacings-get(2);
    }
  }
}
</style>