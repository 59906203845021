import type { CURRENCY_CODE as CURRENCY_CODE_ENUM } from '@/enums/common';

export const mappedCurrencySymbol: Record<CURRENCY_CODE_ENUM | string, string> = {
	[CURRENCY_CODE.AUD]: '$', // Australian Dollar
	[CURRENCY_CODE.CAD]: '$', // Canadian Dollar
	[CURRENCY_CODE.EUR]: '€', // Euro
	[CURRENCY_CODE.GBP]: '£', // British Pound Sterling
	[CURRENCY_CODE.HKD]: '$', // Hong Kong Dollar
	[CURRENCY_CODE.JPY]: '¥', // Japanese Yen
	[CURRENCY_CODE.MYR]: 'RM', // Malaysian Ringgit
	[CURRENCY_CODE.NPR]: 'Rs', // Nepalese Rupee
	[CURRENCY_CODE.NZD]: '$', // New Zealand Dollar
	[CURRENCY_CODE.PHP]: '₱', // Philippine Peso
	[CURRENCY_CODE.SGD]: 'S$', // Singapore Dollar
	[CURRENCY_CODE.THB]: '฿', // Thai Baht
	[CURRENCY_CODE.TWD]: 'NT$', // New Taiwan Dollar
	[CURRENCY_CODE.USD]: '$', // United States Dollar
};