import type { ErrorResponse, FilterInfo, List } from '@/types/common';
import type {
	ProfileResponse,
	UserEventFilterInfo,
	UserEventResponse,
	TicketDetailResponse,
	UserGroupRoleResponse,
	TicketOwnershipResponse,
	CardListResponse,
} from '@/types/profile';
import type { TicketReceiptResponse } from '@/types/ticket';

// TODO: Implement a new API endpoint for this deprecated API in the future
export const getUserByIdAPI = (id: number) => {
	return useRequest<{data: { user: ProfileResponse}}>(`${USER_ENDPOINT}/${id}`, REQUEST_METHOD.GET);
};

export const getUserAttendingEventByIdAPI = (id: number, filter: UserEventFilterInfo) => {
	return useRequest<List<UserEventResponse>>(`${USERS_ENDPOINT}/${id}/events`, REQUEST_METHOD.GET, {}, filter);
};

export const getUserHostingEventByIdAPI = (id: number, filter: UserEventFilterInfo) => {
	return useRequest<List<UserEventResponse>>(`${USERS_ENDPOINT}/${id}/events-hosting`, REQUEST_METHOD.GET, {}, filter);
};

export const getUserAttendanceByEventId = (userId: number, eventId: number) => {
	return useRequest<TicketDetailResponse, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/events-attendances`, REQUEST_METHOD.GET, {}, {
		eventId,
	});
};

export const getUserGroupRole = (profileId: number, userGroupId: number) => {
	return useRequest<UserGroupRoleResponse, ErrorResponse>(`${USERS_ENDPOINT}/${profileId}/account/groups/${userGroupId}`, REQUEST_METHOD.GET);
};

export const getTicketOwnership = (eventId: number) => {
	return useRequest<TicketOwnershipResponse, ErrorResponse>(`${USERS_ENDPOINT}/me/events-attending/${eventId}`, REQUEST_METHOD.GET);
};

export const postFollowGroup = (userId: number, groupId: number, role: string) => {
	return useRequest<{}, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/groups`, REQUEST_METHOD.POST, {
		groupId,
		role,
	});
};

export const deleteUnFollowGroup = (userId: number, groupId: number) => {
	return useRequest<{}, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/groups/${groupId}`, REQUEST_METHOD.DELETE);
};

export const getCardListByUserId = (userId: number, filterInfo?: FilterInfo) => {
	return useRequest<CardListResponse, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/payment-cards`, REQUEST_METHOD.GET, {}, {
		sort: filterInfo?.sortBy,
		page: filterInfo?.currentPage,
		limit: filterInfo?.itemPerPage,
	});
};

export const deleteCardById = (userId: number, cardId: string) => {
	return useRequest<{}, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/payment-cards`, REQUEST_METHOD.DELETE, {
		stripePaymentMethodId: cardId,
	});
};

export const postTicketReceiptById = (userId: number, salesId: number) => {
	return useRequest<TicketReceiptResponse, ErrorResponse>(`${USERS_ENDPOINT}/${userId}/account/ticket-purchases/${salesId}/receipt`, REQUEST_METHOD.POST);
};