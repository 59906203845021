export enum TABLE_SORT_DIRECTION {
	ASC = '$sortAsc',
	DESC = '$sortDesc',
}

export enum SORT_DIRECTION {
	ASC = 'asc',
	DESC = 'desc',
}

export enum ALIGNMENT {
	START = 'start',
	END = 'end',
	CENTER = 'center',
}

export enum ENVIRONMENT {
	DEVELOPMENT = 'development',
	STAGING = 'staging',
	PRODUCTION = 'production',
}

export enum ARROW_ICON_DIRECTION {
	UP = 'up',
	DOWN = 'down',
}

export enum DATE_FILTER {
	ANY_DATE = 'Any date',
	TODAY = 'Today',
	TOMORROW = 'Tomorrow',
	THIS_WEEK = 'This week',
	THIS_WEEKEND = 'This weekend',
	NEXT_WEEK = 'Next week',
	CUSTOM = 'Custom',
}

export enum CURRENCY_CODE {
	AUD = 'AUD',
	CAD = 'CAD',
	EUR = 'EUR',
	GBP = 'GBP',
	HKD = 'HKD',
	JPY = 'JPY',
	MYR = 'MYR',
	NPR = 'NPR',
	NZD = 'NZD',
	PHP = 'PHP',
	SGD = 'SGD',
	THB = 'THB',
	TWD = 'TWD',
	USD = 'USD',
}