export const DATE_TIME_ISO8601_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const TIME_12_HOUR_FORMAT = 'h:mm A';
export const TIME_12_HOUR_2_DIGITS_FORMAT = 'hh:mm A';
export const TIME_24_HOUR_FORMAT = 'HH:mm';
export const DISPLAY_DATE_FORMAT = 'dddd, MMMM D, YYYY';
export const DATE_PICKER_FORMAT = 'MM/DD/YYYY';
export const AMERICAN_DATE_FORMAT = 'YYYY-MM-DD';
export const SETTING_DISPLAY_DATE_FORMAT = 'MMMM DD, YYYY';
export const EXPIRY_DATE_FORMAT = 'MM/YYYY';
export const SHORTEN_EXPIRY_DATE_FORMAT = 'MM/YY';
export const NAME_OF_THE_DAY_OF_THE_WEEK_FORMAT = 'dddd';
export const EVENT_DISPLAY_DATE_FORMAT = 'MMM D YYYY';
