<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '20',
	},
	height: {
		type: String,
		default: '20',
	},
});
const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);

</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M13.3334 11.1454C16.2766 11.724 18.3334 13.0456 18.3334 14.5833C18.3334 16.6544 14.6025 18.3333 10.0001 18.3333C5.39771 18.3333 1.66675 16.6544 1.66675 14.5833C1.66675 13.0456 3.72357 11.724 6.66675 11.1454M10.0001 14.1667V7.5M10.0001 7.5C11.3808 7.5 12.5001 6.38071 12.5001 5C12.5001 3.61929 11.3808 2.5 10.0001 2.5C8.61937 2.5 7.50008 3.61929 7.50008 5C7.50008 6.38071 8.61937 7.5 10.0001 7.5Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
