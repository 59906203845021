<script setup lang="ts">
const props = defineProps({
	color: {
		type: String,
		default: '',
	},
	width: {
		type: String,
		default: '20',
	},
	height: {
		type: String,
		default: '20',
	},
});
const color = computed(() => props.color);
const { color: iconColor } = useIconColor(color);

</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.99998 10.8337C11.3807 10.8337 12.5 9.71437 12.5 8.33366C12.5 6.95295 11.3807 5.83366 9.99998 5.83366C8.61927 5.83366 7.49998 6.95295 7.49998 8.33366C7.49998 9.71437 8.61927 10.8337 9.99998 10.8337Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.99998 18.3337C13.3333 15.0003 16.6666 12.0156 16.6666 8.33366C16.6666 4.65176 13.6819 1.66699 9.99998 1.66699C6.31808 1.66699 3.33331 4.65176 3.33331 8.33366C3.33331 12.0156 6.66665 15.0003 9.99998 18.3337Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
