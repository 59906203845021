<script setup lang="ts">
import type { TextInputType, TextInputVariant } from '@/types/input';

const props = defineProps({
	label: {
		type: String,
		default: undefined,
	},
	required: {
		type: Boolean,
		default: false,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	placeholder: {
		type: String,
		default: undefined,
	},
	type: {
		type: String as PropType<TextInputType>,
		default: 'text',
	},
	variant: {
		type: String as PropType<TextInputVariant>,
		default: 'default',
	},
	modelValue: {
		type: [String, Number] as PropType<string | number | null>,
		default: '',
	},
	modelModifiers: {
		type: Object,
		default: () => ({}),
	},
	errorMessage: {
		type: String,
		default: undefined,
	},
	isShowCustomError: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'update:modelValue', value: string | number | null): void
	(e: 'focus'): void
	(e: 'blur'): void
	(e: 'change'): void
	(e: 'enter'): void
}>();

const inputElement = ref<HTMLInputElement | null>(null);
defineExpose({ input: inputElement });
const isFocus = ref(false);

function handleClickLabel() {
	inputElement.value?.focus();
}

function handleInput(event: Event) {
	const targetElement = event.target as HTMLInputElement;
	let value: string | number | null = targetElement.value.toString();

	// TODO: Add support for comma separator
	if (props.modelModifiers.integer) {
		value = processInteger(value, props.modelValue);
	} else if (props.modelModifiers.float) {
		value = processFloat(value);
	} else if (props.modelModifiers.floatWithZero) {
		value = processFloat(value, true);
	}

	targetElement.value = value?.toString().trim() ?? '';
	emit('update:modelValue', value);
}

function handleFocus() {
	isFocus.value = true;
	emit('focus');
}

function handleBlur() {
	isFocus.value = false;
	emit('blur');
}

function handleChange(e: Event) {
	// Trim space
	const target = e.target as HTMLInputElement;
	const trimValue = target.value.trim();
	emit('update:modelValue', trimValue);
	emit('change');
}

function handlePressEnter(event: Event) {
	event.preventDefault();
	emit('enter');
}

const isError = computed(() => {
	return !!props.errorMessage;
});
</script>

<template>
  <div
    class="input-container"
  >
    <label
      v-if="label"
      class="label text-sm text-medium"
      @click="handleClickLabel"
    >
      {{ label }}
    </label>
    <div
      :class="[
        'text-field-container',
        {
          '--prepend': $slots['prepend'],
          '--append': $slots['append'],
        }
      ]"
    >
      <div
        v-if="$slots['prepend']"
        class="prepend"
      >
        <slot name="prepend" />
      </div>
      <div
        :class="[
          'text-field-wrapper',
          {
            'text-field-underlined': variant === 'underlined',
            '--focus': isFocus,
            '--error': isError,
            '--disabled': disabled
          }
        ]"
      >
        <slot name="leftIcon" />
        <input
          ref="inputElement"
          :value="modelValue"
          :required="required"
          :readonly="readonly"
          :type="type"
          :placeholder="placeholder"
          :disabled="disabled"
          :class="[
            'text-field',
            {
              '--error': isError,
            }
          ]"
          @input="handleInput"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
          @keydown.enter="handlePressEnter"
        >
        <slot name="rightIcon" />
      </div>
      <div
        v-if="$slots['append']"
        class="append"
      >
        <slot name="append" />
      </div>
    </div>
    <p
      v-if="!isShowCustomError && isError"
      class="error-message"
    >
      {{ errorMessage }}
    </p>
    <p
      v-else-if="$slots['helperText']"
      class="helper-text"
    >
      <slot name="helperText" />
    </p>
  </div>
</template>

<style lang="scss" scoped>
.input-container {
  display: flex;
  flex-direction: column;

  .label {
    color: inherit;
  }

  .text-field-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border: 1px solid colors-get(gray, 300);
    @include border-radius-default;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    box-sizing: border-box;

    .text-field {
      position: relative;
      padding: rem(10) rem(14) rem(10) rem(14);
      width: 100%;
      @include fonts-get(regular, text-md);
      color: colors-get(gray, 900);
      background: colors-get(base, white);
      outline: none;
      cursor: inherit;

      @include border-radius-default;
      border: none;

      &:disabled {
        background: colors-get(gray, 50);
        color: colors-get(gray, 500);

        &::placeholder {
          color: colors-get(gray, 400);
        }
      }

      &::placeholder {
        color: colors-get(gray, 400);
      }
    }

    &.text-field-underlined {
      border-top-color: transparent;
      border-right-color: transparent;
      border-left-color: transparent;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: none;

      .text-field {
        &:disabled {
          background: none;
          color: colors-get(gray, 300);
        }

        &::placeholder {
          color: colors-get(gray, 300);
        }
      }
    }

    &.--focus {
      border-bottom-color: colors-get(primary, 300);

      &:not(&.text-field-underlined) {
        color: colors-get(gray, 900);
        border: 1px solid colors-get(primary, 300);
        box-shadow:
          0px 0px 0px 4px #f4ebff,
          0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }

    &.--error {
      border-bottom-color: colors-get(error, 500);

      &:not(&.text-field-underlined) {
        border: 1px solid colors-get(error, 300);

        &.--focus {
          box-shadow:
            0px 0px 0px 4px #fee4e2,
            0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
      }
    }

    &.--disabled {
      &:not(&.text-field-underlined) {
        background: colors-get(gray, 50);
      }
    }
  }

  .text-field-container {
    display: flex;

    &.--prepend {
      .text-field-wrapper {
        border-left-color: transparent;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: none;
      }

      .prepend {
        height: 100%;
        padding: rem(10) rem(14);
        color: colors-get(gray, 600);
        border: 1px solid colors-get(gray, 300);
        border-radius: spacings-get(2) 0 0 spacings-get(2);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }

    &.--append {
      .text-field-wrapper {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .append {
        border: 1px solid colors-get(gray, 300);
        border-left: none;
        @include border-radius-default;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        :deep(.btn) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
      }
    }
  }
}
</style>
