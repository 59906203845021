<script setup lang="ts">
interface MenuList {
	route: string,
	name: string
}

const menuList: MenuList[] = [
	{
		route: ROUTE.EVENT_CREATE.NAME,
		name: 'Create event',
	}, {
		route: ROUTE.PROFILE_ATTENDING.NAME,
		name: 'My tickets',
	}, {
		route: ROUTE.PROFILE_HOSTING.NAME,
		name: 'My profile and events',
	}, {
		route: ROUTE.SETTINGS.NAME,
		name: 'Settings',
	},
];

defineEmits<{ (e: 'onSignOut'): void; }>();

const { isMobile } = useWindowResize();
const profileStore = useProfileStore();

const isOpen = ref(false);

const buttonSize = computed(() => {
	return isMobile.value ? BtnSize[0] : BtnSize[2];
});

function handleClose() {
	isOpen.value = false;
}

onMounted(() => {
	if (!profileStore.profileId) {
		profileStore.myAccountInfo();
	}
});

watch(() => profileStore.profileData, () => {
	if (profileStore.profile && !profileStore.profileGroupId) {
		profileStore.initializeProfileGroup(profileStore.profile);
	}
});
</script>
<template>
  <div class="user-nav">
    <!-- Navigation menu in desktop screen -->
    <template v-if="!isMobile">
      <RouterLink :to="ROUTE.EVENT_CREATE.PATH">
        <BaseButton
          variant="subtle"
          color="gray"
          :size="buttonSize"
        >
          Create event
        </BaseButton>
      </RouterLink>
      <RouterLink
        :to="{
          name: ROUTE.PROFILE_ATTENDING.NAME,
        }"
      >
        <BaseButton
          variant="subtle"
          color="gray"
          :size="buttonSize"
        >
          My tickets
        </BaseButton>
      </RouterLink>
      <div
        id="menu-activator"
        class="cursor-pointer"
      >
        <AvatarImage :image-url="profileStore.profile?.avatarUrl" />
      </div>
      <VMenu
        v-model="isOpen"
        activator="#menu-activator"
        location="bottom end"
        width="200"
      >
        <VList>
          <VListItem
            :ripple="false"
            :to="{
              name: ROUTE.PROFILE_HOSTING.NAME,
            }"
          >
            <VListItemAction>My profile and events</VListItemAction>
          </VListItem>
          <VListItem
            :ripple="false"
            :to="{
              name: ROUTE.SETTINGS.NAME
            }"
          >
            <VListItemAction>Settings</VListItemAction>
          </VListItem>
          <VListItem
            :ripple="false"
            @click="$emit('onSignOut')"
          >
            <VListItemAction>Sign out</VListItemAction>
          </VListItem>
        </VList>
      </VMenu>
    </template>
    <!-- End Navigation menu in desktop screen -->

    <!-- Navigation menu in mobile screen -->
    <template v-else>
      <AvatarImage
        :image-url="profileStore.profile?.avatarUrl"
        @click="isOpen = true"
      />
      <MenuSideDrawer
        :is-open="isOpen"
        @on-close="handleClose"
      >
        <VList class="menu-list">
          <VListItem
            v-for="(menu, index) in menuList"
            :key="index"
            :ripple="false"
            :to="{
              name: menu.route,
            }"
            @click="handleClose"
          >
            <VListItemAction>{{ menu.name }}</VListItemAction>
          </VListItem>
          <VListItem
            tag="a"
            :ripple="false"
            @click="() => {
              handleClose
              $emit('onSignOut')
            }"
          >
            <VListItemAction>Sign out</VListItemAction>
          </VListItem>
        </VList>
      </MenuSideDrawer>
    </template>
    <!-- End Navigation menu in mobile screen -->
  </div>
</template>

<style scoped lang="scss">
.user-nav {
  display: flex;
  align-items: center;
  gap: spacings-get(3);
}
</style>