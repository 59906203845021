import type { List } from '@/types/common';
import type { EventInfo } from '@/types/event';
import type {
	ProfileResponse,
	UserEventFilterInfo,
	UserEventResponse,
	TicketDetailResponse,
	TicketInfo,
	UserGroupResponse,
	GroupInfo,
	UserGroupRoleResponse,
	TicketOwnershipResponse,
} from '@/types/profile';
import type { TicketReceiptResponse } from '@/types/ticket';

interface UserStore {
	userData: {
		data: ProfileResponse | null
		isLoading: boolean
	},
	userGroupData: {
		data: UserGroupResponse | null
		isLoading: boolean
	},
	groupInfoData: {
		data: GroupInfo | null
		isLoading: boolean
	},
	ticketData: TicketDetailResponse | null
	userEventAttending: {
		isLoading: boolean
		response: List<UserEventResponse> | null
		errorMessage: string
	}
	userEventHosting: {
		isLoading: boolean
		singleResponse: List<UserEventResponse> | null
		allResponse: List<UserEventResponse> | null
		errorMessage: string
	}
	ownership: {
		data: TicketOwnershipResponse | null,
		errorMessage: string,
	},
	userTicketReceipt: {
		data: TicketReceiptResponse | null,
		isLoading: boolean
		errorMessage: string
	}
	groupRoleData: UserGroupRoleResponse | null,
	isLoading: boolean
	errorMessage: string
}

const useProfileStore = defineStore('user', {

	state: (): UserStore => ({
		userData: {
			data: null,
			isLoading: false,
		},
		userGroupData: {
			data: null,
			isLoading: false,
		},
		groupInfoData: {
			data: null,
			isLoading: false,
		},
		ticketData: null,
		userEventAttending: {
			isLoading: false,
			response: null,
			errorMessage: '',
		},
		userEventHosting: {
			isLoading: false,
			singleResponse: null,
			allResponse: null,
			errorMessage: '',
		},
		ownership: {
			data: null,
			errorMessage: '',
		},
		userTicketReceipt: {
			data: null,
			isLoading: false,
			errorMessage: '',
		},
		groupRoleData: null,
		isLoading: false,
		errorMessage: '',
	}),

	getters: {
		user: (state: UserStore) => {
			if (!state.userData.data) {
				return null;
			}

			return transformUser(state.userData.data);
		},

		userGroupId: (state: UserStore): number | null => {
			if (!state.userGroupData.data) {
				return null;
			}

			return state.userGroupData.data?.id;
		},

		userGroup: (state: UserStore) => {
			if (!state.groupInfoData.data) {
				return null;
			}

			return state.groupInfoData.data;
		},

		ticketInfo: (state: UserStore): TicketInfo | null => {
			if (!state.ticketData) {
				return null;
			}

			return transformTicket(state.ticketData);
		},

		ticketIds: (state: UserStore) => {
			if (!state.ticketData) {
				return '';
			}

			return state.ticketData.orders.map((order) => order.id).join(',');
		},

		userEventAttendingList: (state: UserStore): EventInfo[] => {
			if (!state.userEventAttending.response) {
				return [];
			}

			return transformUserEventAttendingList(state.userEventAttending.response.data);
		},

		userEventHostingList: (state: UserStore): EventInfo[] => {
			if (!state.userEventHosting.singleResponse) {
				return [];
			}

			return transformUserEventHostingList(state.userEventHosting.singleResponse.data);
		},

		isFollowable: (state: UserStore): boolean => {
			if (!state.userEventHosting.allResponse) {
				return false;
			}
			return !!state.userEventHosting.allResponse.data.length;
		},

		hasOwnership: (state: UserStore) => {
			if (!state.ownership.data) {
				return false;
			}

			return !!state.ownership.data.id;
		},

		userTicketReceiptInfo: (state: UserStore) => {
			if (!state.userTicketReceipt.data) {
				return null;
			}

			return transformTicketReceipt(state.userTicketReceipt.data);
		},
	},

	actions: {
		async getUserById(id: number) {
			this.userData.isLoading = true;
			const { data: result, errorMessage } = await getUserByIdAPI(id);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (result.value) {
				this.userData.data = result.value.data.user;
			}
			this.userData.isLoading = false;
		},

		async getUserGroupsByUserId(userId: number) {
			this.userGroupData.isLoading = true;

			const { data: userGroups, errorMessage } = await getUserGroups(userId);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (userGroups.value && !!userGroups.value.data.length) {
				const firstGroup = userGroups.value.data[0];
				this.userGroupData.data = firstGroup;
			}
			this.userGroupData.isLoading = false;
		},

		async getUserGroupInfo(groupId: number) {
			this.groupInfoData.isLoading = true;
			this.groupInfoData.data = null;

			const { data: groupInfo, errorMessage } = await getGroupInfoByGroupId(groupId);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (groupInfo.value) {
				this.groupInfoData.data = groupInfo.value;
			}
			this.groupInfoData.isLoading = false;
		},

		async getUserAttendingEventById(id: number, filter: UserEventFilterInfo) {
			this.userEventAttending.isLoading = true;
			const { data: result, errorMessage } = await getUserAttendingEventByIdAPI(id, filter);

			if (errorMessage.value) {
				this.userEventAttending.errorMessage = errorMessage.value;
			}

			if (result.value) {
				this.userEventAttending.response = result.value;
			}
			this.userEventAttending.isLoading = false;
		},

		async getUserHostingEventBySingleFilter(id: number, filter: UserEventFilterInfo) {
			const { data: result, errorMessage } = await getUserHostingEventByIdAPI(id, filter);

			if (errorMessage.value) {
				this.userEventHosting.errorMessage = errorMessage.value;
			}

			if (result.value) {
				this.userEventHosting.singleResponse = result.value;
			}
		},

		async getUserHostingEventByAllFilter(id: number, filter: UserEventFilterInfo) {
			const selectedFilter = filter.filter;

			// Define the filter payloads
			const filters = [
				USER_EVENT_HOSTING_FILTER.ARCHIVED,
				USER_EVENT_HOSTING_FILTER.UPCOMING,
			];

			// Create promises for both API calls
			const promises = filters.map((item) => getUserHostingEventByIdAPI(id, {
				...filter,
				filter: item,
				itemPerPage: item === selectedFilter ? PAGINATION_CONFIG.ITEM_PER_PAGE : 1, // Fetch only a single event if the filter does not match the selected filter
			}));

			// Wait for both API calls to complete
			const results = await Promise.all(promises);

			// Extract the data and error messages from the results
			const [archivedResult, upcomingResult] = results;

			// Handle error messages
			if (archivedResult.errorMessage.value || upcomingResult.errorMessage.value) {
				switch (selectedFilter) {
					case USER_EVENT_HOSTING_FILTER.ARCHIVED:
						this.userEventHosting.errorMessage = archivedResult.errorMessage.value;
						break;
					case USER_EVENT_HOSTING_FILTER.UPCOMING:
						this.userEventHosting.errorMessage = upcomingResult.errorMessage.value;
						break;
					default:
						this.userEventHosting.errorMessage = 'Something went wrong, please try again.';
						break;
				}
			}

			if (
				archivedResult.data.value?.data &&
				upcomingResult.data.value?.data &&
				Array.isArray(archivedResult.data.value?.data) &&
				Array.isArray(upcomingResult.data.value?.data)
			) {
				// Ensure data is an array before merging
				const archivedEvents = archivedResult.data.value.data;
				const upcomingEvents = upcomingResult.data.value.data;

				switch (selectedFilter) {
					case USER_EVENT_HOSTING_FILTER.ARCHIVED:
						this.userEventHosting.singleResponse = {
							data: archivedEvents,
							paginationInfo: archivedResult.data.value.paginationInfo,
						};
						break;
					case USER_EVENT_HOSTING_FILTER.UPCOMING:
						this.userEventHosting.singleResponse = {
							data: upcomingEvents,
							paginationInfo: upcomingResult.data.value.paginationInfo,
						};
						break;
					default:
						this.userEventHosting.singleResponse = null;
						break;
				}

				// Merge the response arrays
				const mergedResponse = [...archivedEvents, ...upcomingEvents];

				// Set the merged response
				this.userEventHosting.allResponse = {
					data: mergedResponse,
					paginationInfo: archivedResult.data.value.paginationInfo || upcomingResult.data.value.paginationInfo,
				};
			} else {
				this.userEventHosting.singleResponse = null;
				this.userEventHosting.allResponse = null;
			}
		},

		async getUserHostingEventById(id: number, filter: UserEventFilterInfo, isOrganizerFacing: boolean = false) {
			this.userEventHosting.isLoading = true;

			if (isOrganizerFacing) {
				await this.getUserHostingEventBySingleFilter(id, filter);
			} else {
				await this.getUserHostingEventByAllFilter(id, filter);
			}

			this.userEventHosting.isLoading = false;
		},

		async getTicketDetail(userId: number, eventId: number) {
			this.isLoading = true;
			this.ticketData = null;

			const { data, errorMessage } = await getUserAttendanceByEventId(userId, eventId);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.ticketData = data.value;
			}
			this.isLoading = false;
		},

		async follow(
			userId: number,
			groupId: number,
			role: string = FOLLOWING_TYPE.FOLLOWER,
		) {
			this.isLoading = true;

			const { errorMessage } = await postFollowGroup(userId, groupId, role);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}
			this.isLoading = false;
		},

		async unfollow(userId: number, groupId: number) {
			this.isLoading = true;

			const { errorMessage } = await deleteUnFollowGroup(userId, groupId);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}
			this.isLoading = false;
		},

		async getGroupRole(profileId: number, userGroupId: number) {
			this.isLoading = true;
			this.groupRoleData = null;

			const { data, errorMessage } = await getUserGroupRole(profileId, userGroupId);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.groupRoleData = data.value;
			}
			this.isLoading = false;
		},

		async checkOwnership(eventId: number) {
			this.isLoading = true;
			this.ownership.data = null;

			const { data, errorMessage } = await getTicketOwnership(eventId);

			if (errorMessage.value) {
				this.ownership.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.ownership.data = data.value;
			}
			this.isLoading = false;
		},

		async getTicketReceipt(userId: number, saleId: number) {
			this.userTicketReceipt.isLoading = true;
			this.userTicketReceipt.data = null;

			const { data, errorMessage } = await postTicketReceiptById(userId, saleId);

			if (errorMessage.value) {
				this.userTicketReceipt.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.userTicketReceipt.data = data.value;
			}
			this.userTicketReceipt.isLoading = false;
		},
	},
});

export default useProfileStore;
