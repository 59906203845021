import type { EventInfo } from '@/types/event';
import type { EventSearchResponse } from '@/types/search';

export const transformEventSearchResponse = (events: EventSearchResponse[]): EventInfo[] => {
	return events.map((event: EventSearchResponse): EventInfo => {
		const timezone = event.start.timezone;
		const startDateTime = event.start.utc;
		const displayDate = formatEventDisplayDate(startDateTime, timezone);
		const displayTime = convertDateTimeWithStrictTimezone(
			startDateTime,
			timezone,
			TIME_12_HOUR_FORMAT,
		);
		return {
			locationType: event.locationType,
			id: event.id,
			status: event.status,
			coverImageUrl: event.coverImage,
			name: event.name,
			address: event.address,
			displayDate,
			displayTime,
			displayTimezone: getTimezoneAbbreviation(timezone),
			isHappeningNow: calculateHappeningNow(event.start, event.end),
			isEventSeries: event.badges.isSeries,
			avatarUrl: event.organizer.avatar,
			organizer: event.organizer.nickname,
			organizerId: event.organizer.id,
		};
	});
};